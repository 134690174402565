body {
    line-height: inherit;
}

.error-template {
    padding: 40px 15px;
    text-align: center;
}

.error-actions {
    margin-top: 15px;
    margin-bottom: 15px;
}

.error-actions .btn {
    margin-right: 10px;
}

.table-condensed {
    font-size: 14px;
}

.table-striped-double tbody tr:nth-child(4n+1),
.table-striped-double tbody tr:nth-child(4n+2) {
    background-color: rgba(0, 0, 0, 0.03);
}

.table-striped-double thead tr th,
.table-striped-double td[rowspan='2'],
.table-striped-double tbody tr:nth-child(4n+2),
.table-striped-double tbody tr:nth-child(2n+2) {
    border-bottom: 1px solid darkgrey;
}

.table-striped-double > tbody > tr > td,
.table-striped-double > tbody > tr > th {
    border-top: none;
}

.table-row-border tr td, .table-row-border tr th {
    border-bottom: 1px solid darkgrey !important;
}

table .btn.disabled, table .btn:disabled {
    opacity: 0.15;
}

.toast-success {
    color: #fff;
    background-color: #28a745 !important;
}

.toast-error {
    color: #fff;
    background-color: #dc3545 !important;
}

.action-bar {
    width: 140px;
}

.form-check {
    margin-bottom: 15px;
}

.card-columns {
    column-count: 1 !important;
}

.table-xs {
    font-size: 12px;
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 1 !important;
    }
}

@media (min-width: 768px) {
    .card-columns {
        column-count: 2 !important;
    }
}

@media (min-width: 992px) {
    .card-columns {
        column-count: 2 !important;
    }
}

@media (min-width: 1600px) {
    .card-columns {
        column-count: 3 !important;
    }
}

.react-bootstrap-table {
    overflow-x: auto;
}

.react-bootstrap-table {
    th {
        outline: none;

        &.sortable {
            .svg-icon-sort {
                opacity: 0;
            }

            &:hover {
                cursor: pointer;

                .svg-icon-sort {
                    opacity: 1;
                }
            }
        }
    }

    .table.table-head-custom thead tr {
        .sortable-active {
            color: #3699ff !important;
        }
    }

    td {
        height: 41px;
    }
}

.svg-icon{
    &.svg-icon-sm {
        svg {
            height: 1rem!important;
            width: 1rem!important;
        }
    }
}

.competitors-link {
    text-decoration: underline;
    cursor: pointer;
}

.query-form {
    position: relative;
    padding: 1rem;
    border: 0.2rem solid #ececec;
    border-radius: 8px;
    margin-right: 0;
    margin-left: 0;
    color: #212529;
}

.react-tag-input {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 2.375em;
    padding: .1875em .375em;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1rem;
    background: #fff;
    color: #333;
    border: 1px solid #e1e1e1;
    border-radius: 3px
}

.react-tag-input, .react-tag-input * {
    box-sizing: border-box
}

.react-tag-input > * {
    margin: .1875em
}

.react-tag-input__input {
    width: auto;
    flex-grow: 1;
    height: 1.875em;
    padding: 0 0 0 .1875em;
    margin: 0 .1875em;
    font-size: 1em;
    line-height: 1;
    background: transparent;
    color: #333;
    border: none;
    border-radius: 3px;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: none
}

.react-tag-input__input:-moz-placeholder, .react-tag-input__input:-ms-input-placeholder, .react-tag-input__input::-moz-placeholder, .react-tag-input__input::-webkit-input-placeholder {
    color: #333
}

.react-tag-input__input:-moz-placeholder, .react-tag-input__input:-ms-input-placeholder, .react-tag-input__input::-moz-placeholder, .react-tag-input__input::-webkit-input-placeholder, .react-tag-input__input::placeholder {
    color: #333
}

.react-tag-input__input:focus {
    border: none
}

.react-tag-input__tag {
    position: relative;
    display: flex;
    align-items: center;
    font-size: .85em;
    line-height: 1;
    background: #e1e1e1;
    border-radius: 3px
}

.react-tag-input__tag__content {
    outline: 0;
    border: none;
    white-space: nowrap;
    padding: 0 .46875em
}

.react-tag-input__tag__remove {
    position: relative;
    height: 2em;
    width: 2em;
    font-size: .85em;
    cursor: pointer;
    background: #d4d4d4;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px
}

.react-tag-input__tag__remove:after, .react-tag-input__tag__remove:before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: " ";
    height: .9em;
    width: .15em;
    background-color: #333
}

.react-tag-input__tag__remove:before {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform: translateX(-50%) translateY(-50%) rotate(45deg)
}

.react-tag-input__tag__remove:after {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    transform: translateX(-50%) translateY(-50%) rotate(-45deg)
}

.react-tag-input__tag__remove-readonly {
    width: 0
}

.react-tag-input__tag__remove-readonly:after, .react-tag-input__tag__remove-readonly:before {
    content: "";
    width: 0
}

.tender {

    &-title a {
        color: #212121;
        font-weight: 500;
        -ms-word-break: break-word;
        word-break: break-word;
        text-transform: uppercase;
        display: block;
    }

    &-platform {
        width: 16px;
        height: 16px;
    }
}

.tender-list td {
    padding: 0 !important;
}

.pr-6 {
    padding-right: 60px !important;
}

.nav-item .fa {
    cursor: pointer;
}

.highlight em {
    font-style: normal;
    font-weight: bold;
    color: #dc3545;
}

.tender-background {
    background-color: #f9f9f9;
}

.bs-canvas-overlay {
    opacity: 0;
    z-index: -1;
}

.bs-canvas-overlay.show {
    opacity: 0.85;
    z-index: 1100;
}

.bs-canvas {
    top: 0;
    width: 80%;
    z-index: 1110;
    overflow-x: hidden;
    overflow-y: auto;

    @media (max-width: 768px) {
        width: 100%;
    }

    &-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1040;
        width: 100vw;
        height: 100vh;
        background-color: #000;

        &.fade {
            opacity: 0;
        }

        &.show {
            opacity: .5;
        }
    }
}

.bs-canvas-left {
    left: 0;
}

.bs-canvas-right {
    right: 0;
}

.bs-canvas-anim {
    transition: all .4s ease-out;
    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -ms-transition: all .4s ease-out;
}

.bs-canvas-close:focus {
    outline: none;
}

.nav-tabs {
    .nav-link {
        box-shadow: 0 0 1px 0 rgba(0,0,0,0.18);
    }
}
